<template>
  <div class="dashboard">
    <div class="tw-w-full tw-flex tw-flex-row header-div tw-items-center  ">
      <v-col sm="3" class="padi-logo tw-cursor-pointer">
        <img @click="$router.push({name: 'HomePage'})"
          :src="logo"
          alt="company logo"
          style="width: auto; height: 30px"
        />
      </v-col>
    </div>
    <div class="booking-body-div tw-w-full tw-flex tw-pt-10 ">
      <v-col sm="12" class="tw-w-full md:tw-px-16 tw-px-5 general-div">
        <div class="tw-flex lg:tw-justify-between tw-items-center">
          <div class="tw-pt-8 lg:tw-pt-0">
            <p class="header">Hey Buddy,</p>

            <p class="header-text">
              Fill in your details to hasten your booking process
            </p>
          </div>
          <div class="booking-img-container">
            <img
                src="@/assets/icons/booking.png"
                alt="booking-svg"
                style="width: 361px; height: 288px"
            />
          </div>
        </div>
        <div
            class="
        tw-flex
        tw-flex-row
        tw-justify-center
        tw-items-center
        tw-bg-white
        tw-rounded-lg
        tw-py-8
      "
        >

          <div class="mr-2 tw-bg-white tw-rounded-lg tw-p-0 tw-w-full" style="">
            <form @submit.prevent="verifyDetailExist">
              <v-text-field
                  placeholder="Enter your phone number (090 0000 0000) or email to retrieve your traveller's code"
                  class="p-0 tw-px-0"
                  solo

                  hide-details
                  v-model="search"
              >
                <template #append>
                  <v-btn text type="submit">
                    <v-icon :color="companyColor" class="tw-m-0 tw-p-0">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-progress-linear
                  v-if="searchLoading"
                  indeterminate
                  :color="companyColor"
                  height="2"
              />

            </form>
          </div>
        </div>
        <v-divider/>
        <div class="tw-w-full tw-pt-8">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitBookingCode)">
              <div class="tw-flex flipping">
                <v-col sm="12" lg="6"  md="6" class="data left">

                  <validation-provider name="first name" rules="required" v-slot=" {classes, errors} ">
                    <p class="form-header">First Name</p>
                    <div :class="classes">
                      <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                                    v-model="passengerDetail.firstName"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>
                  </validation-provider>

                </v-col>
                <v-col sm="12" lg="6"  md="6" class="data right">
                  <p class="form-header">Last Name</p>
                  <validation-provider name="last name" rules="required" v-slot=" {classes, errors} ">
                    <div :class="classes">
                      <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                                    v-model="passengerDetail.lastName"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>
                  </validation-provider>

                </v-col>
              </div>
              <div class="tw-flex flipping">
                <v-col sm="12" lg="6"  md="6" class="data left">
                  <p class="form-header"> Phone Number</p>
                  <phone-number @getNumber="getNumber" color="#004aad" :phone-number-exist="passengerDetail.phoneNumber" />
                </v-col>
                <v-col sm="12" lg="6"  md="6" class="data right">
                  <p class="form-header">Email</p>
                  <validation-provider name="Email" rules="required" v-slot="{classes, errors}">
                    <div :class="classes">
                      <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                                    color="#004aad" v-model="passengerDetail.email"></v-text-field>
                      <span> {{errors[0]}} </span>
                    </div>

                  </validation-provider>
                </v-col>

              </div>
              <div class="tw-flex flipping">
                <v-col sm="12" lg="6"  md="6" class="data left">
                  <validation-provider
                      name="Gender"
                      rules="required"
                      v-slot="{ classes, errors }"
                  >
                    <p class="form-header">Gender</p>
                    <div  :class="classes">
                      <v-combobox
                          :items="genders"
                          append-icon="mdi-chevron-down"
                          v-model="passengerDetail.gender"
                          hide-details
                          solo
                          placeholder="Male"
                      ></v-combobox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                </v-col>
              </div>

              <div class="next-of-kin-details">

                <v-col><p class="nof tw-py-8">Next Of Kin Details</p></v-col>

                <div class="tw-flex flipping">
                  <v-col sm="12" lg="6"  md="6" class="data left">

                    <validation-provider name="first name" rules="required" v-slot=" {classes, errors} ">
                      <p class="form-header">First Name</p>
                      <div :class="classes">
                        <v-text-field solo placeholder="John" type="text" color="#004aad" hide-details
                                      v-model="passengerDetail.nextOfKinFirstName"></v-text-field>
                        <span> {{errors[0]}} </span>
                      </div>
                    </validation-provider>

                  </v-col>
                  <v-col sm="12" lg="6"  md="6" class="data right">
                    <p class="form-header">Last Name</p>
                    <validation-provider name="last name" rules="required" v-slot=" {classes, errors} ">
                      <div :class="classes">
                        <v-text-field solo placeholder="Doe" type="text" color="#004aad" hide-details
                                      v-model="passengerDetail.nextOfKinLastName"></v-text-field>
                        <span> {{errors[0]}} </span>
                      </div>
                    </validation-provider>

                  </v-col>
                </div>
                <div class="tw-flex flipping">
                  <v-col sm="12" lg="6"  md="6" class="data left">
                    <p class="form-header"> Phone Number</p>
                    <phone-number @getNumber="getNextOfKinNumber" color="#004aad" />

                  </v-col>
                  <v-col sm="12" lg="6"  md="6" class="data right">
                    <p class="form-header">Email</p>
                    <validation-provider name="Email" rules="required" v-slot="{classes, errors}">
                      <div :class="classes">
                        <v-text-field solo placeholder="example@gmail.com" hide-details type="email"
                                      color="#004aad" v-model="passengerDetail.nextOfKinEmail"></v-text-field>
                        <span> {{errors[0]}} </span>
                      </div>

                    </validation-provider>
                  </v-col>

                </div>
                <div class="tw-flex flipping">
                  <v-col sm="12" lg="6"  md="6" class="data left">
                    <validation-provider
                        name="Gender"
                        rules="required"
                        v-slot="{ classes, errors }"
                    >
                      <p class="form-header">Gender</p>
                      <div  :class="classes">
                        <v-combobox
                            :items="genders"
                            append-icon="mdi-chevron-down"
                            v-model="passengerDetail.nextOfKinGender"
                            hide-details
                            solo
                            placeholder="Male"
                        ></v-combobox>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </v-col>

                  <v-col sm="12" lg="6"  md="6" class="data left">
                    <validation-provider
                        name="Relationship"
                        rules="required"
                        v-slot="{ classes, errors }">
                      <p class="form-header">Relationship</p>
                      <div  :class="classes">
                        <v-combobox
                            :items="relationships"
                            append-icon="mdi-chevron-down"
                            v-model="passengerDetail.relationship"
                            hide-details
                            solo
                            placeholder="Brother"
                        ></v-combobox>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </v-col>
                </div>
              </div>
              <v-col
                  class="
              tw-flex tw-w-full tw-justify-end tw-items-center
              action-btn-div
              tw-py-8
            "
              >
                <base-button button-text="Submit" class="second-btn" type="submit" :loading="loading"></base-button>
              </v-col>
            </form>
          </validation-observer>
        </div>

      </v-col>
    </div>

    <modal
        :dialog="detailExist"
        @close="handleDetailExistModal"
        icon="thumbs-up"
        title="Awesome!"
        description="Your data exist, a verification code has been sent to the registered phone number, click on the x button to enter the code"
    />
    <modal
        :dialog="detailDoesNotExist"
        @close="handleDetailDoesNotExist"
        :showButton="true"
        icon="oops"
        title="Oops!"
        description="Your travelling details does not exist, kindly
      close and fill the form"
    />
    <verify-modal
        :dialog="verifyDialog"
        @close="handleVerifyModal"
        :done-loading="verifyLoading"
        @verifyAction="handleVerify"
    />

    <booking-code-modal
        :dialog="showBookingCode"
        @close="handleShowBookingCode"
        message="Your Booking Code"
        status="displayCode"
        :description="bookingCodeResponse"
    />
  </div>
</template>

<script>
import PhoneNumber from "@/components/reuseables/PhoneNumber";
import BaseButton from "@/components/reuseables/BaseButton";
import BookingCodeModal from "@/components/reuseables/BookingCodeModal";
import Modal from "@/components/reuseables/ModalTwo";
import VerifyModal from "@/components/reuseables/VerificationCodeModal";
import {
  addBookingCode,

  getBookingCodeByPhoneNumber,
  sendOtpToPassenger,
  verifyPassengerDetailExist,
  verifyPassengerOtp
} from "@/services/api/APIService";
export default {
  name: "TravellerCodeComponent",
  components: {BookingCodeModal, Modal, VerifyModal, BaseButton, PhoneNumber},
  data() {
    return {
      logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1631672100/assets/frontend/logo_kpzfg4.png",
      passengerDetail:{

      },
      companyColor : "#004AAD",
      searchLoading : false,
      search:"",
      genders: ["male","female"],
      gender: "",
      relationships: [
        "Brother",
        "Sister",
        "Daughter",
        "Son",
        "Niece",
        "Nephew",
        "Father",
        "Mother",
        "Aunt",
        "Uncle",
        "Relative",
        "Husband",
        "Wife"
      ],
      detailExist: false,
      detailDoesNotExist: false,
      verifyDialog : false,
      showBookingCode : false,
      verifyLoading : false,
      bookingCodeResponse : "",
      loading: false
    };
  },
  computed: {

  },
  methods: {
    handleVerifyModal() {
      this.verifyDialog = !this.verifyDialog;
    },
    handleDetailDoesNotExist() {
      this.detailDoesNotExist = !this.detailDoesNotExist;
    },
    handleDetailExistModal() {
      if (this.detailExist) {
        this.handleVerifyModal();
      }
      this.detailExist = !this.detailExist;
    },
    handleShowBookingCode() {
      if (this.showBookingCode){
        this.$nextTick(() => this.passengerDetail = {})
      }
      this.showBookingCode = !this.showBookingCode;

    },
    showBookingCodeModal() {
      this.dialog = true;
    },
    async handleVerify(code) {
      this.verifyLoading = true;
      let data = {};
      let pin_id = sessionStorage.getItem("pin_id");
      if (code && pin_id) {
        data.pin = code;
        data.pin_id = pin_id;
        data.api_key = process.env.VUE_APP_TERMII_API_KEY;
        await verifyPassengerOtp(data)
            .then(async (res) => {
              await getBookingCodeByPhoneNumber(res.data.msisdn)
                  .then((bookingCodeRes) => {
                    console.log(bookingCodeRes.data);
                    this.bookingCodeResponse = bookingCodeRes.data.bookingCode;
                    this.handleVerifyModal();
                    this.handleShowBookingCode();
                    this.verifyLoading = false;
                  })
                  .catch((err) => {
                    console.log(err.response);
                    this.verifyLoading = false;
                    this.$displaySnackbar({
                      message: err.response,
                      success: false,
                    });
                  });
            })
            .catch((err) => {
              console.log(err.response);
              this.verifyLoading = false;
              this.$displaySnackbar({
                message: err.response,
                success: false,
              });
            });
      }
    },
    closeModal() {
      this.dialog = !this.dialog;
    },
    validatePhone(phoneNumber) {
      // let num = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
      if (phoneNumber.length === 13) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Phone number not valid e.g 2348000000000",
          success: false,
        });
        return false;
      }
    },
    validEmail(email) {
      let valid =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(valid)) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
    async handleDetailExist(detail) {
      this.searchLoading = true;
      await verifyPassengerDetailExist(detail)
          .then(async (res) => {
            let data = {};
            data.api_key = process.env.VUE_APP_TERMII_API_KEY;
            data.message_type = "NUMERIC";
            data.to = res.data.phoneNumber;
            data.from = "NOMADICPOD";
            data.channel = "generic";
            data.pin_attempts = 3;
            data.pin_time_to_live = 5;
            data.pin_length = 5;
            data.pin_type = "NUMERIC";
            data.pin_placeholder = 55050;
            data.message_text =
                "Your One Time verification to retrieve your booking code is " +
                data.pin_placeholder;
            await sendOtpToPassenger(data)
                .then((otpRes) => {
                  console.log(otpRes.data);
                  sessionStorage.setItem("pin_id", otpRes.data.pinId);
                  this.handleDetailExistModal();
                  this.searchLoading = false;
                })
                .catch((err) => {
                  console.log(err.response);
                  this.searchLoading = false;
                  this.$displaySnackbar({
                    message: err.response,
                    success: false,
                  });
                });
          })
          .catch((err) => {
            console.log(err.response);
            this.searchLoading = false;
            this.handleDetailDoesNotExist();
          });
    },
    async verifyDetailExist() {
      let search = "";
      if (this.search) {
        if (this.search.startsWith("0")) {
          search = this.search.replace("0", "234");
          if (this.validatePhone(search)) {
            await this.handleDetailExist(search);
          }
        } else if (this.search.startsWith("234")) {
          if (this.validatePhone(this.search)) {
            await this.handleDetailExist(this.search);
          }
        } else if (this.validEmail(this.search)) {
          await this.handleDetailExist(this.search);
        }
      }
    },
    getNumber(number){
      this.passengerDetail.phoneNumber= number
    },
    getNextOfKinNumber(number){
      console.log(number)
      this.passengerDetail.nextOfKinPhoneNumber= number
    },
    async submitBookingCode() {
      if (
          this.validatePhone(this.passengerDetail.nextOfKinPhoneNumber) &&
          this.validEmail(this.passengerDetail.nextOfKinEmail)
      ) {
      console.log(this.passengerDetail)
        this.loading = true;
        await addBookingCode(this.passengerDetail)
            .then((res) => {
              this.loading = false;

              this.bookingCodeResponse = res.data.BookingCode;
              this.showBookingCode = !this.showBookingCode;
              sessionStorage.removeItem("bookingCodeDetail");
              this.$displaySnackbar({
                message: "Your booking Code was sent to your email",
                success: true,
              });
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
      }
    }
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  background: white;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.booking-body-div {
  padding-right: 24px;
  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
}

.header-div{
    position: fixed;
    background-color: #FFFFFF;
    z-index: 10;

}

.booking-body {
  background: #f6f7f9;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
}
.padi-logo{
  padding-left: 3rem;
  @media screen and (max-width: 1024px) {
    padding-left: 1rem;
  }
}

.header {
  font-family: "Inter";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
  @media screen and (max-width: 767px) {
    margin: 0;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.header-text {
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  height: 75px;
  width: 371px;
  color: rgba(79, 79, 79, 1);

  @media screen and (max-width: 767px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-top: 13px;
    letter-spacing: 0em;
    text-align: left;
    width: 217px;
    height: 32px;
    color: rgba(79, 79, 79, 1);
  }
}

.general-div{
  padding: 0 24rem;

  @media screen and (max-width: 1440px) {
    padding: 0 16rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 .5rem;
  }
}

.flipping {
  @media (max-width:960px) {
    flex-direction: column;
  }
}
.booking-img-container {
  @media screen and (max-width: 767px) {
    img {
      display: none;
    }
  }
}
</style>
