<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style="width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="
          tw-flex
          tw-justify-center
          tw-items-center
          tw-self-end
          tw-cursor-pointer
        "
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white" />
      </div>

      <div
        class="
          tw-flex
          card
          tw-p-10 tw-justify-center tw-items-center tw-flex-col
        "
        style="min-height: 384px"
      >
        <icons :name="icon" :color="companyColor" />
        <p class="title tw-py-3" :style="{ color: companyColor }">
          {{ title }}
        </p>
        <label class="desc"> {{ description }} </label>
        <div
          class="
            tw-flex tw-w-full tw-justify-center tw-items-center
            action-btn-div
            md:tw-mt-10
            tw-mt-5
          "
          v-if="showButton"
        >
          <!-- <v-btn
            text
            class="first-btn"
            @click="$emit('close')"
            :style="{
              color: companyColor,
              border: '1px solid' + companyColor,
            }"
          >
            Cancel
          </v-btn> -->
          <v-btn
            text
            class="second-btn"
            @click="$emit('close')"
            :style="{ background: companyColor, color: 'white' }"
          >
            Close
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/confirm-delete.svg";

import Icons from "../../components/reuseables/Icons";

export default {
  name: "DeleteModal",
  components: { Icons },
  props: {
    dialog: Boolean,
    showButton: {
      type: Boolean,
      default: false,
    },
    doneLoading: Boolean,
    icon: {
      type: String,
      default: "confirm-delete",
    },
    // icon: [String],
    title: [String],
    description: [String],
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
  },
  data() {
    return {
      check,
      companyColor: "#004aad",
      companyData: {},
    };
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (min-width: 768px) {
    width: 30% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
  @media screen and (max-width: 768px) {
    font-size: 20px !important;
    margin-bottom: 16px !important;
    padding-bottom: 0px !important;
  }
}
.desc {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;

  @media screen and (max-width: 768px) {
    font-weight: 500;
  }
}

.bottomtext {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.place-top {
  position: absolute;
  top: 15%;
  /* top: 50px; */
}

.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    /* margin-left: 20px; */
  }
}
</style>
